<template>
  <div class="campaign-setup-links">
    <campaign-setup-links-subids @input="setSubids" />

    <h3 class="tw-mt-0 tw-text-xl">
      Recommended
    </h3>
    <skeleton-box v-if="isFetchingLinks" height="195" width="100%" />
    <campaign-setup-links-main
      v-else-if="recommendedLinkIndex !== undefined"
      :link="links[recommendedLinkIndex]"
      @copy-link="copyLink"
      @preview="preview"
      @image-click="onClickImage(links[recommendedLinkIndex], images.indexOf(links[recommendedLinkIndex]))" />
    <div v-if="isFetchingLinks || links.length > 1" class="campaign-setup-links__normal">
      <header class="campaign-setup-links-header">
        <div class="campaign-setup-links-header__left">
          <h3 class="tw-my-0 tw-text-xl">
            Funnels
          </h3>
        </div>
        <div class="campaign-setup-links-header__middle">
          <p>No. Events</p>
        </div>
        <div class="campaign-setup-links-header__right">
          <p>Actions</p>
        </div>
      </header>
      <template v-if="isFetchingLinks">
        <div
          v-for="index in 3"
          :key="index"
          class="campaign-setup-links__item">
          <skeleton-box height="16" width="40%" />
        </div>
      </template>
      <template v-for="(link, index) in links" v-else>
        <campaign-setup-links-item
          v-if="index !== recommendedLinkIndex"
          :key="link.landing_page_id"
          :link="link"
          class="campaign-setup-links__item"
          @copy-link="copyLink"
          @preview="preview"
          @image-click="onClickImage(link,images.indexOf(link))" />
      </template>
    </div>
    <app-lightbox
      v-if="lightboxVisible && lightboxLink"
      :is-visible="lightboxVisible"
      :image-url="lightboxLink.image"
      :title="lightboxLink.title"
      @close="lightboxVisible = false"
      @navigation="handleNavigation" />
  </div>
</template>

<script>
import CampaignSetupLinksMain from '@/components/setup/CampaignSetupLinksMain';
import CampaignSetupLinksItem from '@/components/setup/CampaignSetupLinksItem';
import CampaignSetupLinksSubidSkeleton from '@/components/skeletons/setup/CampaignSetupLinksSubidSkeleton';
import CampaignSetupLinksSubids from '@/components/setup/CampaignSetupLinksSubids';
import LinkGenerationService from '@/services/link-generation-service';
import CopyToClipboardService from '@/services/copy-to-clipboard-service';
import SkeletonBox from '@/components/skeletons/SkeletonBox';
import AppLightbox from '@/components/AppLightbox';

export default {
  name: 'CampaignSetupLinks',
  components: {
    CampaignSetupLinksSubids,
    CampaignSetupLinksSubidSkeleton,
    CampaignSetupLinksMain,
    CampaignSetupLinksItem,
    SkeletonBox,
    AppLightbox
  },
  data() {
    return {
      lightboxVisible: false,
      lightboxLinkIndex: 0,
      showSkeletons: true,
      subIds: [
        {
          subId: '',
          label: 'C1',
          preview: 'aff'
        },
        {
          subId: '',
          label: 'C2',
          preview: 'test'
        },
        {
          subId: '',
          label: 'C3',
          preview: 'link'
        }
      ]
    };
  },
  computed: {
    orgId() {
      return this.$store.getters.organization.id;
    },
    campaignId() {
      return this.$route.params.id;
    },
    isFetchingLinks() {
      return this.$store.getters.isFetchingLinks;
    },
    links() {
      return this.$store.getters.linksById(this.campaignId);
    },
    images() {
      return this.links.filter((link) => link.image);
    },
    recommendedLinkIndex() {
      return 0;
    },
    lightboxLink() {
      return this.images[this.lightboxLinkIndex];
    }
  },
  watch: {
    campaignId() {
      this.fetchLinks();
    }
  },
  created() {
    this.fetchLinks();
  },
  methods: {
    setSubids(ids) {
      this.subIds = ids;
    },
    fetchLinks() {
      this.$store.dispatch('fetchLinks', {
        orgId: this.orgId,
        campaignId: this.campaignId
      });
    },
    preview(template) {
      const formattedLink = LinkGenerationService.generate(
        template,
        this.subIds[0].preview,
        this.subIds[1].preview,
        this.subIds[2].preview
      );
      window.open(formattedLink, '_blank').focus();
    },
    copyLink(template) {
      const formattedLink = LinkGenerationService.generate(
        template,
        this.subIds[0].subId,
        this.subIds[1].subId,
        this.subIds[2].subId
      );

      CopyToClipboardService.copy(formattedLink)
        .then((val) => {
          this.$message({
            showClose: true,
            message: 'Copied to clipboard'
          });
        })
        .catch((e) => {
          this.$message({
            message: 'There was an error copying the link.',
            type: 'error'
          });
        });
    },
    onClickImage(link, index) {
      if (link.image) {
        this.lightboxLinkIndex = index;
        this.lightboxVisible = true;
      }
    },
    handleNavigation(direction) {
      const index = this.lightboxLinkIndex + direction;
      this.lightboxLinkIndex = index < 0 ? this.images.length - 1 : index % this.images.length;
    }
  }
};
</script>

<style lang="scss">
.campaign-setup-links {
  @include stage-layout;

  @apply tw-mt-0 tw-p-layout-1 md:tw-p-0 tw-bg-transparent #{!important};

  &-header {
    display: flex;
    margin: $--clb-space-4 0 $--clb-space-2;
    transition: $--all-transition;
    text-align: center;
    align-items: center;

    p {
      font-size: $--clb-font-size-xs;
      font-weight: 700;
      flex: 1 1 0;
    }

    &__left {
      flex: 3;
      text-align: left;
    }

    &__middle {
      flex: 1;
    }

    &__right {
      flex: 1;
    }

    .campaign-setup-links__title {
      margin: 0;
    }
  }

  &__item {
    border-radius: $--clb-border-radius;
    margin-bottom: $--clb-space-3 !important;

    @apply tw-bg-white;
  }

  &__normal {
    .skeleton-box {
      margin: 21px $--clb-space-3;
    }
  }
}
</style>
