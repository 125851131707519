<template>
  <div :class="{'app-node-strip--vertical': isVertical}" :style="nodeStripStyles" class="app-node-strip">
    <div :class="{'app-node-strip__line--vertical': isVertical}" :style="lineStyles" class="app-node-strip__line" />
    <div class="app-node-strip__nodes">
      <template v-for="node in nodes">
        <div :key="node" :style="nodeBoxStyles" class="app-node-strip__node-box">
          <div :class="{ 'app-node-strip__node--active': isActiveNode(node) }" class="app-node-strip__node" />
          <span :class="{ 'app-node-strip__title--active': isActiveNode(node), 'app-node-strip__title--vertical': isVertical}" class="app-node-strip__title">
            {{ node }}
          </span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
const baseNodeSize = 14; // Must be in sync with scss variable
const minNodeBoxWidth = 95;

export default {
  name: 'AppNodeStrip',
  props: {
    activeNodes: {
      type: Array,
      default() {
        return [];
      }
    },
    nodes: {
      type: Array,
      required: true
    },
    isVertical: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    nodeStripStyles() {
      return this.isVertical
        ? `height: ${baseNodeSize * 3 * this.nodes.length}px`
        : `min-width: ${this.nodes.length * minNodeBoxWidth}px;`;
    },
    nodeBoxStyles() {
      return this.isVertical
        ? { 'flex-basis': this.nodesWidth, 'max-height': this.nodesWidth }
        : { 'flex-basis': this.nodesWidth, 'max-width': this.nodesWidth };
    },
    nodesWidth() {
      return `${100 / this.nodes.length}%`;
    },
    lineStyles() {
      return this.isVertical
        ? { width: '1px', height: `${100 - (100 / this.nodes.length)}%` }
        : { height: '1px', width: `${100 - (100 / this.nodes.length)}%` };
    }
  },
  methods: {
    isActiveNode(node) {
      return this.activeNodes.includes(node);
    }
  }
};
</script>

<style lang="scss">
$--base-node-size: 14px; // Must be in sync with JS variable

.app-node-strip {
  position: relative;
  padding-top: ($--base-node-size / 2);

  &__nodes {
    display: flex;
    position: relative;
    top: -($--base-node-size / 2);
  }

  &__node-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: visible;
  }

  &__node {
    position: relative;
    width: $--base-node-size;
    height: $--base-node-size;
    border-radius: 50%;
    background: $--clb-node-color;

    &--active {
      background: $--clb-color-primary;

      &::before {
        content: ' ';
        position: absolute;
        width: round($--base-node-size * 1.3);
        height: round($--base-node-size * 1.3);
        padding: $--base-node-size / 10;
        border-radius: 50%;
        left: -(round($--base-node-size * 0.3) / 2);
        top: -(round($--base-node-size * 0.3) / 2);
        border: 1px solid $--clb-color-primary;
        background-clip: content-box;
      }
    }
  }

  &__line {
    display: flex;
    height: 1px;
    margin: 0 auto;
    background: $--clb-node-line-color;

    &--vertical {
      margin: auto 0;
      left: 0;
      top: 0;
    }
  }

  &__title {
    font-size: $--clb-font-size-xs !important;
    margin-top: $--clb-space-2;

    &--active {
      color: $--clb-color-primary;
    }
  }

  &--vertical {
    display: flex;
    align-items: flex-start;
    height: $--base-node-size * 10;
    margin-left: ($--base-node-size / 2);
    padding-top: 0;

    .app-node-strip__nodes {
      flex-direction: column;
      height: 100%;
      top: 0;
      left: -($--base-node-size / 2);
    }

    .app-node-strip__title {
      margin: 0 0 0 $--clb-space-2;
    }

    .app-node-strip__node-box {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
  }
}
</style>
