<template>
  <div class="campaign-setup-links-main">
    <app-preview-card
      :size="imageSize"
      :background-image="link.image"
      @click.native="handleImageClick" />
    <div class="campaign-setup-links-main__content tw-overflow-x-hidden">
      <small class="campaign-setup-links-main__title-id">#{{ link.landing_page_id }}</small>
      <p class="campaign-setup-links-main__title">
        <span class="campaign-setup-links-main__title-name">{{ link.title }}</span>
        <el-tag
          class="campaign-setup-links-main__title-badge"
          type="green"
          size="mini"
          effect="dark">
          Top Performer
        </el-tag>
        <el-tag
          v-if="isNew"
          class="campaign-setup-links-main__title-badge"
          type="danger"
          size="mini"
          effect="dark">
          New
        </el-tag>
        <span v-if="link.redirectless == 'yes'" class="campaign-setup-links-main__title-redirectless"> Redirectless </span>
      </p>
      <p v-if="link.events.length" class="campaign-setup-links-main__event-count">
        {{ eventsCount }}
      </p>
      <div class="campaign-setup-links-main__nodes tw-overflow-x-auto">
        <app-node-strip
          v-if="link.events.length"
          v-scrollable:[scrollableArg]
          :is-vertical="isVertical"
          :nodes="link.events"
          :active-nodes="link.events" />
      </div>
      <div class="campaign-setup-links-main__buttons">
        <el-button-group>
          <el-button class="next-button campaign-setup-links-main__button" icon="el-icon-view" @click="handlePreview">
            Preview
          </el-button>
          <el-button class="next-button campaign-setup-links-main__button" icon="el-icon-link" @click="handleCopyLink">
            Copy Link
          </el-button>
        </el-button-group>
      </div>
    </div>
  </div>
</template>

<script>
import AppNodeStrip from '@/components/AppNodeStrip';
import AppPreviewCard from '@/components/AppPreviewCard';
import Breakpoints from '@/mixins/Breakpoints';
import dateService from '@/services/date';

export default {
  name: 'CampaignSetupLinksMain',
  components: { AppNodeStrip, AppPreviewCard },
  mixins: [Breakpoints([450, 768])],
  props: {
    link: {
      type: Object,
      required: true
    }
  },
  computed: {
    isNew() {
      return dateService.parseISOString(this.link.created) >= new Date(Date.now() - 60 * 60 * 24 * 14 * 1000);
    },
    isVertical() {
      return this.windowBreakpoint < 768;
    },
    scrollableArg() {
      return !this.isVertical ? 'x' : '';
    },
    imageSize() {
      const width = this.windowBreakpoint;
      return width <= 450 ? 'small' : width >= 768 ? 'large' : 'medium';
    },
    eventsCount() {
      return `${this.link.events.length} Events`;
    }
  },
  methods: {
    handlePreview() {
      this.$emit('preview', this.link.url);
    },
    handleCopyLink() {
      this.$emit('copy-link', this.link.url);
    },
    handleImageClick() {
      this.$emit('image-click');
    }
  }
};
</script>

<style lang="scss" >
.campaign-setup-links-main {
  display: flex;
  height: auto;

  @apply tw-p-layout-1 tw-bg-white tw-rounded tw-mb-layout-3;

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-left: $--clb-layout-1;
  }

  .campaign-setup-links-main__title {
    font-weight: 600;
    font-size: $--clb-font-size-base;

    &-id {
      display: block;
      font-size: $--clb-font-size-xs;
    }

    &-name {
      margin-right: $--clb-space-4;
    }

    &-redirectless {
      position: relative;
      top: -3px;
      font-weight: normal;
      line-height: 20px;
      font-size: $--clb-font-size-xs - 2px;
      border-radius: $--clb-space-1;
      color: $--clb-body-font;
      white-space: nowrap;
      padding: $--clb-space-1 $--clb-space-1;
      background: #edf1ff;
      border: 1px solid $--clb-shadow-color;
    }

    &-badge {
      position: relative;
      top: -2px;
      line-height: 20px;
      font-size: $--clb-font-size-xs - 2px;
      margin-right: $--clb-space-1;
    }

    &-badge.el-tag--green {
      top: -3px;
      background: #81cf74;
      border: #81cf74;
    }
  }

  &__nodes {
    display: flex;
  }

  &__event-count {
    color: $--clb-color-primary;
    font-size: 14px !important;
  }

  .app-node-strip,
  &__buttons {
    margin-top: $--clb-layout-1;
  }

  .el-button-group {
    width: 100%;
    transition: none;

    .campaign-setup-links-main__button {
      display: inline-block;
      margin: 0;
      height: 40px;
      width: 50% !important;
      font-size: $--clb-font-size-xs;
      transition: $--all-transition, width 0ms;

      i {
        font-size: $--clb-font-size-sm;
      }

      &:first-child {
        border-right: 1px solid rgba(255, 255, 255, 0.75);
        margin-right: 0 !important;
      }

      &:active,
      &:hover,
      &:focus {
        transform: translateY(0) !important;
      }
    }
  }

  @media (min-width: $--xs) {
    &__content {
      padding-left: $--clb-layout-4;
    }

    .app-node-strip,
    &__buttons {
      margin-top: $--clb-layout-2;
    }

    .el-button-group {
      width: auto;
    }
  }

  @media (min-width: $--sm) {
    .campaign-setup-links-main__title {
      font-weight: 600;
      font-size: $--clb-font-size-lg;
    }
  }

  @media (min-width: $--md) {
    .el-button-group {
      .campaign-setup-links-main__button {
        font-size: $--clb-font-size-sm;
        width: 128px !important;
      }
    }
  }
}
</style>
