// EXAMPLE TEMPLATE STRINGS
//  https://some.tracking.domain/asdkjajhsjhjahsdjkhasjkdasd?c1=xxc1xx&c2=xxc2xx&c3=xxc3xx
//  https://some.tracking.domain/asdkjajhsjhjahsdjkhasjkdasd/xxc1xx/xxc2xx/xxc3xx
//  https://some.advertiser.domain/path?other=values&random=&utm_content=asjdhasjdhakshdjkahsa/xxc1xx/xxc2xx/xxc3xx&val=other

export default {
  generate: (url, ...subids) => {
    return url.replace(/xxc([1-3])xx/gi, (val, key) => {
      const placeholder = subids[parseInt(key) - 1] || '';
      return /[/?&#]/gi.test(placeholder)
        ? encodeURIComponent(placeholder)
        : placeholder;
    });
  }
};
