<template>
  <div :class="{ 'campaign-setup-links-item--active' : isExpanded }" class="campaign-setup-links-item" @click.stop="handleItemClick">
    <div class="campaign-setup-links-item__top">
      <p class="campaign-setup-links-item__title">
        <span class="campaign-setup-links-item__title-id hidden-xs"> #{{ link.landing_page_id }} </span>
        <span class="campaign-setup-links-item__title-name"> {{ link.title }} </span>
        <el-tag
          v-if="isNew"
          class="campaign-setup-links-item__title-badge"
          type="danger"
          size="mini"
          effect="dark">
          New
        </el-tag>
        <span v-if="link.redirectless == 'yes'" class="campaign-setup-links-item__title-redirectless"> Redirectless </span>
      </p>
      <div class="campaign-setup-links-item__information">
        {{ eventsCount }}
      </div>
      <div class="campaign-setup-links-item__buttons">
        <el-button-group>
          <el-button
            type="primary"
            class="next-button"
            icon="el-icon-view"
            @click.stop="handlePreview">
            Preview
          </el-button>
          <el-button
            type="primary"
            class="next-button"
            icon="el-icon-link"
            @click.stop="handleCopyLink">
            Copy Link
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div v-if="isExpanded" class="campaign-setup-links-item__bottom">
      <app-preview-card
        :size="'small'"
        :background-image="link.image"
        @click.native.stop="handleImageClick" />
      <app-node-strip
        v-if="link.events.length"
        v-scrollable:[scrollableArg]
        :nodes="link.events"
        :active-nodes="link.events"
        :is-vertical="isVertical" />
    </div>
  </div>
</template>

<script>
import AppPreviewCard from '@/components/AppPreviewCard';
import AppNodeStrip from '@/components/AppNodeStrip';
import Breakpoints from '@/mixins/Breakpoints';
import dateService from '@/services/date';

export default {
  name: 'CampaignSetupLinksItem',
  components: { AppPreviewCard, AppNodeStrip },
  mixins: [Breakpoints([768])],
  props: {
    link: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isExpanded: false
    };
  },
  computed: {
    isNew() {
      return dateService.parseISOString(this.link.created) >= new Date(Date.now() - 60 * 60 * 24 * 14 * 1000);
    },
    eventsCount() {
      return this.link.events.length > 0 ? `${this.link.events.length} Events` : '-';
    },
    isVertical() {
      return this.windowBreakpoint < 768;
    },
    scrollableArg() {
      return !this.isVertical ? 'x' : '';
    }
  },
  methods: {
    handleItemClick() {
      this.isExpanded = !this.isExpanded;
    },
    handlePreview() {
      this.$emit('preview', this.link.url);
    },
    handleCopyLink() {
      this.$emit('copy-link', this.link.url);
    },
    handleImageClick() {
      this.$emit('image-click');
    }
  }
};
</script>

<style lang="scss">
.campaign-setup-links-item {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: $--clb-border-radius;
  transition: $--all-transition, max-height 500ms ease-in-out, min-height 300ms ease-out;
  padding: $--clb-space-4;
  cursor: pointer;

  &__top {
    display: flex;
    align-items: center;
  }

  &__information {
    font-size: $--clb-font-size-xs !important;
    padding: 0 $--clb-space-1;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }

  &__title {
    flex: 3 1 0;
    font-size: $--clb-font-size-sm !important;

    &-id {
      display: block;
      font-size: $--clb-font-size-xs;
      line-height: $--clb-font-size-sm;
    }

    &-name {
      margin-right: $--clb-space-2;
      font-weight: 600;
    }

    &-redirectless {
      position: relative;
      top: -2px;
      line-height: 20px;
      font-size: $--clb-font-size-xs - 2px;
      border-radius: $--clb-space-1;
      color: $--clb-body-font;
      white-space: nowrap;
      padding: $--clb-space-1 $--clb-space-1;
      background: #edf1ff;
      border: 1px solid $--clb-shadow-color;
    }

    &-badge {
      position: relative;
      top: -2px;
      font-size: $--clb-font-size-xs - 2px;
      margin-right: $--clb-space-1;
    }
  }

  &__buttons {
    min-width: 60px + $--clb-space-2;
    text-align: right;

    .el-button-group {
      .el-button {
        margin: 0;
        padding: 0;
        height: 36px;
        width: 36px;
        font-size: $--clb-font-size-xs;

        span {
          display: none;
        }

        i {
          font-size: $--clb-font-size-sm;
        }

        &:first-child {
          border-right: 1px solid rgba(255, 255, 255, 0.75);
        }

        &:hover,
        &:active,
        &:focus {
          transform: translateY(0);
        }
      }
    }
  }

  &__bottom {
    padding-bottom: $--clb-space-3;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .app-node-strip {
      margin-left: $--clb-layout-2;
      position: relative;
      top: -14px;
    }
  }

  &:hover {
    box-shadow: $--box-shadow-light;
    transform: translateY(-1px);
    color: $--jb-light-primary-color;
    border-color: $--jb-light-primary-color;
  }

  &:active,
  &.active,
  &:focus {
    border-color: $--jb-dark-primary-color;
    background: $--color-light;
    outline: none;
    transform: translateY(0);
    color: $--jb-dark-primary-color;
  }

  &--active {
    min-height: 150px;
    max-height: 1000px;
  }
}

@media (min-width: $--sm) {
  .campaign-setup-links-item {
    &__top {
      flex-direction: row;
    }

    &__title {
      font-size: $--clb-font-size-base !important;
    }

    &__information {
      font-size: $--clb-font-size-sm !important;
    }

    &__buttons {
      flex-direction: row;
      min-width: 200px + $--clb-space-2;
      justify-content: flex-end;
      padding: 0;

      .el-button-group {
        .el-button {
          width: 100px;
          height: 36px;

          span {
            display: inline;
          }
        }
      }
    }

    &__bottom {
      align-items: center;

      .app-node-strip {
        margin-left: 0;
        position: static;
      }
    }
  }
}
</style>
