<template>
  <section class="campaign-setup-links-subids">
    <div class="campaign-setup-links-subids__header">
      <el-popover
        placement="right"
        title="Capturing Dynamic Data"
        width="300"
        trigger="hover"
        content="Sub IDs allow you to pass information from your traffic source to Jumbleberry, to be used for reporting or tracking pixels. The variables you set here will be available on copied links."
        popper-class="dark campaign-setup-links-subids__header-popover">
        <h3 slot="reference" class="tw-mt-0 tw-text-lg tw-font-semibold">
          Sub IDs
          <i class="el-icon-info" />
        </h3>
      </el-popover>
    </div>
    <div class="campaign-setup-links-subids__form">
      <div v-for="item in subIds" :key="item.label" class="campaign-setup-links-subids__form-item">
        <label for="">{{ item.label }}</label>
        <el-input
          v-model="item.subId"
          size="mini"
          placeholder="Custom Variable"
          type="text"
          @input="handleInputChange" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CampaignSetupLinksSubids',
  data() {
    return {
      subIds: [
        {
          subId: '',
          label: 'C1'
        },
        {
          subId: '',
          label: 'C2'
        },
        {
          subId: '',
          label: 'C3'
        }
      ]
    };
  },
  methods: {
    handleInputChange() {
      this.$emit('input', JSON.parse(JSON.stringify(this.subIds)));
    }
  }
};
</script>

<style lang="scss">
.campaign-setup-links-subids {
  @apply tw-bg-white tw-rounded tw-p-layout-1 tw-mb-layout-3;

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;

    .el-icon-info {
      padding-left: $--clb-space-1;
      color: $--clb-color-accent__dark;
      font-size: $--clb-font-size-base;
    }
  }

  &__form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    &-item {
      width: 30%;
      width: calc(33% - #{$--clb-space-3});
      display: flex;
      flex-direction: column;

      label {
        display: block;
      }

      input {
        margin-top: $--clb-space-1;
      }
    }
  }
}
</style>
