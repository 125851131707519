<template>
  <section class="campaign-setup-links-subid-skeleton">
    <div class="campaign-setup-links-subid__form">
      <form action="">
        <div class="campaign-setup-links-subid__form-item">
          <skeleton-box height="53px" width="85%" />
        </div>
        <div class="campaign-setup-links-subid__form-item middle">
          <skeleton-box height="53px" width="85%" />
        </div>
        <div class="campaign-setup-links-subid__form-item">
          <skeleton-box height="53px" width="85%" />
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import SkeletonBox from '@/components/skeletons/SkeletonBox';

export default {
  name: 'CampaignSetupLinksSubidSkeleton',
  components: { SkeletonBox }
};
</script>

<style lang="scss" scoped>
.campaign-setup-links-subid-skeleton {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: auto;
  padding: $--clb-space-3 0;
  background: #fff;
  border-radius: $--clb-border-radius;

  .middle {
    margin: $--clb-space-6 0;
  }

  .skeleton-box {
    border-radius: $--clb-border-radius;
  }
}

@media (min-width: $--sm) {
  .campaign-setup-links-subid-skeleton {
    width: 100%;
    margin: 0;
    padding: 0;

    form {
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        margin: 0;
      }
    }
  }
}
</style>
